<template>
	<div class="main">
		<div class="head">
			<div class="head_left">
				<span class="head_left_item head_left_date">{{ time.split(' ')[0] }}</span>
				<span class="head_left_item head_left_date">{{ week }}</span>
				<span class="head_left_item head_left_time">{{ time.split(' ')[1] }}</span>
			</div>
			<span class="head_title">派车服务平台</span>
			<i class="el-icon-switch-button head-right" @click="back"></i>
		</div>
		<div class="main-body">
			<div class="main-body_both">
				<BlueBox height="56.48148148148148vh">
					<BoxHead text="派车需求单" height="4.62962962962963vh"></BoxHead>
					<div class="table" style="width: 25.572916666666668vw;height: 48.148148148148145vh;">
						<div class="table-th">
							<span class="flex-1 text-center">申请人</span>
							<span class="flex-2 text-center">目的地</span>
							<span class="flex-2 text-center">出发时间-返回时间</span>
							<span class="table-operation-td text-center">操作</span>
						</div>
						<div v-for="item in dispatchData" :key="item.id" class="table-tr">
							<span class="flex-1 text-center">{{ item.applicant }}</span>
							<span class="flex-2 text-center">{{ item.target }}</span>
							<span class="flex-2 text-center">
								<div>{{ item.dispatch_time }}</div>
								<div>{{ item.return_time }}</div>
							</span>
							<span class="table-operation-td text-center flex justify-content-s-b">
								<div @click="handle" class="table-btn table-btn--blue">处理</div>
								<div @click="handleCall(item.member_id)" class="table-btn table-btn--green">连线</div>
							</span>
						</div>
					</div>
				</BlueBox>
				<BlueBox height="31.11111111111111vh">
					<BoxHead height="4.62962962962963vh" :show-triangle="false">
						<div class="flex" style="margin-left: 1.4583333333333335vw;">
							<div @click="isCarStatusTab = true" class="quadrangle margin-r-10"
								:class="{'quadrangle--active': isCarStatusTab}"><span class="quadrangle_text">车辆状态</span></div>
							<div @click="isCarStatusTab = false" class="quadrangle" :class="{'quadrangle--active': !isCarStatusTab}">
								<span class="quadrangle_text">司机状态</span>
							</div>
						</div>
					</BoxHead>
					<div v-show="isCarStatusTab" class="table" style="height: 22vh;padding: 10px;overflow-y: auto;">
						<div class="table-th">
							<span class="flex-1 text-center">序号</span>
							<span class="flex-1 text-center">车牌号</span>
							<span class="flex-1 text-center">状态</span>
						</div>
						<div v-for="(item, index) in cars" :key="item.id" class="table-tr">
							<span class="flex-1 text-center">{{ index + 1 }}</span>
							<span class="flex-1 text-center">{{ item.car_number }}</span>
							<span class="flex-1 text-center"
								:style="{color: carStatusDict[item.status].color}">{{ carStatusDict[item.status].text }}</span>
						</div>
					</div>
					<div v-show="!isCarStatusTab" class="table" style="height: 22vh;padding: 10px;overflow-y: auto;">
						<div class="table-th">
							<span class="flex-1 text-center">名称</span>
							<span class="flex-1 text-center">电话</span>
							<span class="flex-1 text-center">状态</span>
						</div>
						<div v-for="item in drivers" :key="item.id" class="table-tr">
							<span class="flex-1 text-center">{{ item.name }}</span>
							<span class="flex-1 text-center">{{ item.mobile }}</span>
							<span class="flex-1 text-center"
								:style="{color: driverStatusDict[item.status].color}">{{ driverStatusDict[item.status].text }}</span>
						</div>
					</div>
				</BlueBox>
			</div>

			<div class="main-body_middle">
				<BlueBox height="56.48148148148148vh">
					<img src="https://oss.huarendjk.com/img/2023-04-18/643e5933af576.png" class="main-body_middle-img">
				</BlueBox>
				<BlueBox height="31.11111111111111vh">
					<BoxHead height="4.62962962962963vh" :show-triangle="false">
						<div @click="statisticsType = 1" class="quadrangle margin-r-10"
							:class="{'quadrangle--active': statisticsType == 1}" style="margin-left: 1.4583333333333335vw;">
							<span class="quadrangle_text">车辆统计</span>
						</div>
						<div @click="statisticsType = 2" class="quadrangle margin-r-10"
							:class="{'quadrangle--active': statisticsType == 2}">
							<span class="quadrangle_text">派车统计</span>
						</div>
						<div @click="statisticsType = 3" class="quadrangle" :class="{'quadrangle--active': statisticsType == 3}">
							<span class="quadrangle_text">目的地统计</span>
						</div>
					</BoxHead>
					<div v-show="statisticsType == 1" ref="carStatistics" class="bar-chart"></div>
					<div v-show="statisticsType == 2" ref="driversStatistics" class="bar-chart"></div>
					<div v-show="statisticsType == 3" ref="targetStatistics" class="bar-chart"></div>
				</BlueBox>
			</div>

			<div class="main-body_both">
				<BlueBox height="26.203703703703702vh">
					<BoxHead text="公告" height="4.62962962962963vh"></BoxHead>
					<div class="notice" ref="noticeScroll">
						<div class="item_6_notice"
							:style="{borderBottom: `${index != announcement.length - 1 ? '0.09259259259259259vh solid #01C2FF' : 'none'}`}"
							v-for="(item, index) in announcement" :key="index">
							<div class="item_6_notice_head">
								<span class="item_6_notice_head_title">{{ item.title }}</span>
								<span class="item_6_notice_head_time">{{ item.created_at }}</span>
							</div>
							<div class="item_6_notice_con">{{ item.content }}</div>
						</div>
					</div>
				</BlueBox>
				<BlueBox height="61.01851851851851vh">
					<BoxHead text="已安排" height="4.62962962962963vh"></BoxHead>
					<div class="table" style="width: 25.572916666666668vw;height: 54vh;">
						<div class="table-th">
							<span class="flex-1 text-center">申请人</span>
							<span class="flex-2 text-center">目的地</span>
							<span class="flex-2 text-center">出发时间-返回时间</span>
							<span class="table-operation-td text-center">状态</span>
						</div>
						<div v-for="item in processing" :key="item.id" class="table-tr">
							<span class="flex-1 text-center">{{ item.applicant }}</span>
							<span class="flex-2 text-center">{{ item.target }}</span>
							<span class="flex-2 text-center">
								<div>{{ item.dispatch_time }}</div>
								<div>{{ item.return_time }}</div>
							</span>
							<span class="table-operation-td text-center" :style="{color: processingStatusDict[item.status].color}">
								{{ processingStatusDict[item.status].name }}
							</span>
						</div>
					</div>
				</BlueBox>
			</div>
		</div>

		<VideoCallDialog ref="videoCallDialog"></VideoCallDialog>
	</div>
</template>

<script>
	import BlueBox from './components/BlueBox.vue'
	import BoxHead from './components/BoxHead.vue'
	import VideoCallDialog from '@/components/VideoCallDialog.vue'

	import {
		getDispatchMonitorAPI
	} from '@/api/large.js'

	import {
		formatDate
	} from '@/utils/common-fun'

	export default {
		components: {
			BlueBox,
			BoxHead,
			VideoCallDialog,
		},
		data() {
			return {
				carStatusDict: {
					1: {
						text: '空闲',
						color: '#67C23A'
					},
					2: {
						text: '维修',
						color: '#FF0000'
					},
					3: {
						text: '出车中',
						color: '#FFF279'
					}
				},
				driverStatusDict: {
					1: {
						text: '空闲',
						color: '#67C23A'
					},
					2: {
						text: '已有安排',
						color: '#01BFFC'
					},
					3: {
						text: '出车中',
						color: '#FFF279'
					}
				},
				processingStatusDict: {
					1: {
						name: '待安排',
						color: '#FFF279'
					},
					2: {
						name: '已安排',
						color: '#67C23A'
					},
					3: {
						name: '审批通过',
						color: '#67C23A'
					},
					4: {
						name: '审批驳回',
						color: '#FF0000'
					},
					5: {
						name: '已出营',
						color: '#FFF279'
					},
					6: {
						name: '已回营',
						color: '#67C23A'
					}
				},
				// 左上角日期时间
				time: '2020-10-10 12:00:00',
				// 计算时间循环函数返回值
				timer: null,
				// 左上角星期
				week: '星期一',

				isCarStatusTab: true,
				cars: [],
				drivers: [],
				// 未派车
				dispatchData: [],
				// 已派车
				processing: [],

				// 统计类型，1=车辆 2=派车 3=目的地
				statisticsType: 1,

				// 公告
				announcement: [],
				// 滚动事件回调标识
				scrollTimer: null,
				tabTimer: null,
				dataTimer: null,
				carChart: null,
				driverChart: null,
				targetChart: null,
			}
		},
		created() {
			this.getTime()
			this.getData()

			this.dataTimer = setInterval(() => {
				this.getData()
				this.switchTab()
			}, 20000)

			this.$nextTick(() => {
				this.startScroll();
			})
		},
		beforeDestroy() {
			clearInterval(this.scrollTimer)
			clearInterval(this.dataTimer)
			clearInterval(this.tabTimer)
		},
		methods: {
			switchTab() {
				this.isCarStatusTab = !this.isCarStatusTab

				this.statisticsType += 1

				if (this.statisticsType > 3) {
					this.statisticsType = 1
				}
			},
			getData() {
				getDispatchMonitorAPI().then(res => {
					this.cars = res.cars
					this.drivers = res.drivers
					this.dispatchData = res.carApplication
					this.announcement = res.announcement
					this.processing = res.processing

					this.initCarStatisticsChart(res.stat.cars)
					setTimeout(() => {
						this.initDriverStatisticsChart(res.stat.drivers)
					}, 1000)
					this.initTargetStatisticsChart(res.stat.targets)
				})
			},
			back() {
				if (document.exitFullscreen) document.exitFullscreen()
				else if (document.msExitFullscreen) document.msExitFullscreen()
				else if (document.mozCancelFullScreen) document.mozCancelFullScreen()
				else document.webkitCancelFullScreen()
				clearInterval(this.scrollTimer)
				clearInterval(this.dataTimer)
				clearInterval(this.tabTimer)
				this.$router.back()
			},
			// 滚动检测部分的dom
			startScroll() {
				let that = this;
				this.$nextTick(() => {
					clearInterval(that.scrollTimer)
					that.scrollTimer = setInterval(() => {
						const dom2 = this.$refs.noticeScroll;
						if (dom2) {
							dom2.scrollTop += 1;
							if (Math.ceil(dom2.scrollTop + dom2.clientHeight) >= dom2.scrollHeight) {
								dom2.scrollTop = 0;
							}
						}
					}, 100)
				})
			},
			handle() {
				this.$router.replace('/dispatch-vehicle/service')
			},
			handleCall(memberId) {
				this.$refs.videoCallDialog.open(memberId)
			},
			initCarStatisticsChart(data) {
				let yData = []
				let xData = []

				for (let item of data) {
					yData.push(item.name)
					xData.push(item.count)
				}

				let options = {
					xAxis: {
						type: 'category',
						data: yData,
					},
					yAxis: {
						type: 'value',
						minInterval: 1
					},
					series: [{
						data: xData,
						type: 'bar',
						barWidth: '10%',
					}],
					color: ['#01C2FF'],
					textStyle: {
						color: '#FFF',
					}
				}
				if (!this.carChart) {
					this.carChart = this.$echarts.init(this.$refs.carStatistics)
				}
				this.carChart.setOption(options)
			},
			initTargetStatisticsChart(data) {
				let yData = []
				let xData = []

				for (let item of data) {
					yData.push(item.name)
					xData.push(item.count)
				}

				let options = {
					xAxis: {
						type: 'category',
						data: yData
					},
					yAxis: {
						type: 'value',
						minInterval: 1
					},
					series: [{
						data: xData,
						type: 'bar',
						barWidth: '10%',
					}],
					color: ['#01C2FF'],
					textStyle: {
						color: '#FFF'
					}
				}
				if (!this.targetChart) {
					this.targetChart = this.$echarts.init(this.$refs.targetStatistics)
				}
				this.targetChart.setOption(options)
			},
			initDriverStatisticsChart(data) {
				let yData = []
				let xData = []

				for (let item of data) {
					yData.push(item.name)
					xData.push(item.count)
				}

				let options = {
					xAxis: {
						type: 'category',
						data: yData
					},
					yAxis: {
						type: 'value',
						minInterval: 1
					},
					series: [{
						data: xData,
						type: 'bar',
						barWidth: '10%',
					}],
					color: ['#01C2FF'],
					textStyle: {
						color: '#FFF'
					}
				}
				if (!this.driverChart) {
					this.driverChart = this.$echarts.init(this.$refs.driversStatistics)
				}
				this.driverChart.setOption(options)
			},
			// 获取右上角日期时间展示
			getTime() {
				let that = this
				let time = new Date()
				that.time = formatDate(time)
				let index = time.getDay()

				switch (index) {
					case 0:
						that.week = '星期日'
						break
					case 1:
						that.week = '星期一'
						break
					case 2:
						that.week = '星期二'
						break
					case 3:
						that.week = '星期三'
						break
					case 4:
						that.week = '星期四'
						break
					case 5:
						that.week = '星期五'
						break
					case 6:
						that.week = '星期六'
						break
				}
				let diff = +time % 1000;
				if (diff == 0) {
					// 满足，隔一分钟执行一次
					clearInterval(that.timer);
					that.timer = setInterval(() => {
						that.getTime();
					}, 1000)
				} else {
					// 不满足，相差多少时间，间隔这么长时间后执行
					diff = 1000 - diff;
					clearInterval(that.timer)
					that.timer = setInterval(() => {
						that.getTime();
					}, diff)
				}
			},
		}
	}
</script>

<style scoped lang="less">
	.main {
		width: 100%;
		height: 100vh;
		background-image: url('../../assets/large-screen/large-screen.-bg.jpg');
		background-size: 100% 100vh;
		user-select: none;
	}

	.main-body {
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		width: 97.44791666666667vw;
		height: 88.6111111111111vh;
	}

	.main-body_both {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 26.822916666666668vw;
		height: inherit;
	}

	.bar-chart {
		width: 42.1875vw;
		height: 26.48148148148148vh;
	}

	.quadrangle {
		position: relative;
		width: 4.583333333333334vw;
		height: 3.0555555555555554vh;
		line-height: 3.0555555555555554vh;
		text-align: center;
		color: #29F1FA;

		.quadrangle_text {
			position: relative;
			z-index: 222;
		}
	}

	.quadrangle::after {
		display: block;
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		border: 1px solid #1B7EF2;
		border-radius: 4px;
		width: 4.583333333333334vw;
		height: 3.0555555555555554vh;
		background: linear-gradient(180deg, #323647 0%, #222631 100%) rgba(27, 126, 242, 0.2);
		transform: skewX(-20deg);
		transition: all .3s;
	}

	.quadrangle--active {
		color: #FFF;
	}

	.quadrangle--active::after {
		border: 1px solid #33FEFE;
		background: linear-gradient(180deg, #08B9C1 0%, #0758B8 100%);
	}

	.notice {
		width: 100%;
		height: 21.574074074074073vh;
		overflow-y: scroll;
	}

	::-webkit-scrollbar {
		width: 0 !important;
	}

	::-webkit-scrollbar {
		width: 0 !important;
		height: 0;
	}

	.main-body_middle {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 42.1875vw;
		height: inherit;

		.main-body_middle-img {
			object-fit: cover;
			width: inherit;
			height: inherit;
		}
	}

	.table {
		margin: 0.9259259259259258vh auto;
		overflow-y: auto;

		.table-th {
			display: flex;
			align-items: center;
			position: sticky;
			top: 0;
			width: 100%;
			height: 3.7037037037037033vh;
			font-size: 0.7291666666666667vw;
			font-family: Source Han Sans CN-Bold, Source Han Sans CN;
			font-weight: 700;
			color: #01C2FF;
			background: #0F2B3F;
		}

		.table-tr {
			display: flex;
			align-items: center;
			height: 4.62962962962963vh;
			font-size: 0.625vw;
			color: #FFF;
		}

		.table-operation-td {
			width: 5.520833333333334vw;
		}

		.table-btn {
			border-radius: 4px;
			width: 2.604166666666667vw;
			height: 2.4074074074074074vh;
			color: #FFF;
			font-size: 0.8333333333333334vw;
			line-height: 2.4074074074074074vh;
			transition: background .3s;
		}

		.table-btn:hover {
			opacity: 0.8;
		}

		.table-btn--blue {
			background: #01C2FF;
		}

		.table-btn--green {
			background: #00AA28;
		}
	}

	.head {
		display: flex;
		justify-content: center;
		position: relative;
		width: 100%;
		height: 7.4074074074074066vh;
		background-image: url('../../assets/large-screen/large-head.png');
		background-size: 100% 7.4074074074074066vh;

		.head_left {
			display: flex;
			position: absolute;
			top: 1.3vh;
			left: 2.7083333333333335vw;
		}

		.head_left_item {
			margin-right: 2.0833333333333335vw;
		}

		.head_left_date {
			font-size: 1.25vw;
			color: #45A2FF;
		}

		.head_left_time {
			font-size: 1.25vw;
			color: #FFF;
		}

		.head_title {
			position: relative;
			top: 0.5555555555555555vh;
			font-size: 1.7708333333333335vw;
			font-weight: 700;
			color: #FFFFFF;
		}

		.head-right {
			position: absolute;
			top: 0.5555555555555555vh;
			right: 2.7083333333333335vw;
			color: #ffffff;
			font-size: 1.7708333333333335vw;
			cursor: pointer;
		}
	}

	.item_6_notice {
		width: 23.385416666666668vw;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		padding-bottom: 1vh;

		.item_6_notice_head {
			width: 23.385416666666668vw;
			height: 6.666666666666666vh;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;

			.item_6_notice_head_title {
				font-size: 1vw;
				font-weight: 700;
				color: #FFFFFF;
			}

			.item_6_notice_head_time {
				font-size: 0.8333333333333334vw;
				font-weight: 400;
				color: #FFFFFF;
			}
		}

		.item_6_notice_con {
			width: 23.385416666666668vw;
			min-height: 11.333333333333332vh;
			display: flex;
			flex-wrap: wrap;
			line-height: 3.333333333333333vh;
			font-size: 0.68vw;
			font-weight: 700;
			color: #FFFFFF;
		}
	}
</style>