<template>
	<div class="blue-box" :style="{width, height}">
		<slot></slot>
	</div>
</template>

<script>
	export default {
		props: {
			width: {
				type: String,
				default: '100%'
			},
			height: String,
		}
	}
</script>

<style scoped>
	.blue-box {
		background: rgba(8, 26, 48, 0.8);
		box-shadow: inset 0px 0px 20px 0px rgba(22.000000588595867, 88.00000235438347, 167.00000524520874, 1);
		border-image: linear-gradient(149deg, rgba(35.00000171363354, 133.00000727176666, 223.00000190734863, 1), rgba(34.99999791383743, 133.00006806850433, 223.00000190734863, 0.6499999761581421)) 1 1;
	}
</style>