<template>
	<div class="box-head" :style="{width, height}">
		<div class="box-head_bg"></div>
		<div v-if="showTriangle" class="box-head_triangle"></div>
		<span v-if="text" class="box-head_text">{{ text }}</span>
		<slot></slot>
	</div>
</template>

<script>
	export default {
		props: {
			width: {
				type: String,
				default: '100%'
			},
			height: String,
			text: String,
			showTriangle: {
				type: Boolean,
				default: true
			}
		}
	}
</script>

<style scoped>
	.box-head {
		display: flex;
		align-items: center;
		position: relative;
		width: 100%;
	}

	.box-head::before {
		display: block;
		content: '';
		width: 0.20833333333333334vw;
		height: 100%;
		background: #1B7EF2;
	}

	.box-head_bg {
		position: absolute;
		left: 0;
		right: 0;
		width: inherit;
		height: inherit;
		background: #1B7EF2;
		opacity: 0.14;
	}

	.box-head_triangle {
		position: absolute;
		left: 0.6770833333333334vw;
		right: 0;
		width: 0;
		height: 0;
		border: 0.5208333333333334vw solid;
		border-left-color: #29F1FA;
		border-bottom-color: transparent;
		border-top-color: transparent;
		border-right-color: transparent;
	}

	.box-head_text {
		position: absolute;
		left: 2.03125vw;
		right: 0;
		font-size: 1.25vw;
		font-family: Source Han Sans CN-Bold, Source Han Sans CN;
		font-weight: 700;
		color: #FFF;
	}
</style>